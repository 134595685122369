<div [ngClass]="{ 'aidTypeContent': !isAudibel, 'aidTypeContentAudibel': isAudibel}">I am...</div>
<div [ngClass]="{ 'aidTypeButtonContainer': !isAudibel, 'aidTypeButtonContainerAudibel': isAudibel}">
  <div *ngIf="!isAudibel; else audibel">
    <div><button (click)="onUserTypeClick('Genesis')">wearing <b>Genesis AI</b> hearing aids</button></div>
    <div><button (click)="onUserTypeClick('Evolv')">wearing <b>Evolv AI</b> hearing aids</button></div>
    <div><button (click)="onUserTypeClick('Caregiver')">a family member, friend, or caregiver</button></div>
  </div>
  <ng-template #audibel>
    <div><button (click)="onUserTypeClick('Intrigue')">wearing <b>Intrigue AI</b> hearing aids</button></div>
    <div><button (click)="onUserTypeClick('Arc')">wearing <b>Arc AI</b> hearing aids</button></div>
    <div><button (click)="onUserTypeClick('Caregiver')">a family member, friend, or caregiver</button></div>
  </ng-template>

</div>

