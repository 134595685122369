import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {MatGridListModule} from '@angular/material/grid-list';
import { AidTypeFormComponent } from './components/aid-type-form/aid-type-form.component';
import { CompatabilityResultComponent } from './components/compatability-result/compatability-result.component';
import { LogoComponent } from './components/logo/logo.component';
import { BannerComponent } from './components/banner/banner.component';
import { PcNotSupportedComponent } from './components/pc-not-supported/pc-not-supported.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoadingWheelComponent } from './components/loading-wheel/loading-wheel.component';
import { HttpClientModule } from '@angular/common/http';
import { MyStarkeyRerouteComponent } from './my-starkey-reroute/my-starkey-reroute.component';
import { MyAudibelRerouteComponent } from './my-audibel-reroute/my-audibel-reroute.component';


@NgModule({
  declarations: [
    AppComponent,
    AidTypeFormComponent,
    CompatabilityResultComponent,
    LogoComponent,
    BannerComponent,
    PcNotSupportedComponent,
    LoadingWheelComponent,
    MyStarkeyRerouteComponent,
    MyAudibelRerouteComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatGridListModule,
    MatProgressSpinnerModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
