import { Injectable } from '@angular/core';
import { CompatabilityResultData } from '../model/compatability-result-data';

@Injectable({
  providedIn: 'root'
})
export class ConstantsService {
  public PC_NOT_SUPPORTED: string;

  public PHONE_NOT_SUPPORTED: string;
  public PHONE_SUPPORTED: string;
  public PHONE_SUPPORTED_OS_UPDATE_REQUIRED: string;
  public PHONE_SUPPORTED_STREAMING_NOT_AVAILABLE: string;
  public PHONE_SUPPORTED_TWO_WAY_AUDIO_CAPABLE: string;
  public PHONE_NOT_FULLY_TESTED: string;

  public GENESIS_DOWNLOAD_STATEMENT: string;
  public EVOLV_DOWNLOAD_STATEMENT: string;
  public INTRIGUE_DOWNLOAD_STATEMENT: string;
  public ARC_DOWNLOAD_STATEMENT: string;

  public CAREGIVER_DOWNLOAD_STATEMENT: string;



  public SUPPORTED_ICON_NAME: string;
  public UNRECOGNIZED_DEVICE_ICON_NAME: string;
  public UNSUPPORTED_ICON_NAME: string;
  public UNSUPPORTED_PC_ICON_NAME: string;
  public OS_UPDATE_OR_NO_STREAMING_ICON_NAME: string;

  public MYSTARKEY_LINK: string;
  public MYAUDIBEL_LINK: string;
  public THRIVE_LINK: string;


  constructor() {
    /* PC */
    this.PC_NOT_SUPPORTED = "Sorry, personal computers are not supported.";

    /* Compatability Statements */
    this.PHONE_NOT_SUPPORTED = "Sorry, your device is not recognized and is not supported.";
    this.PHONE_SUPPORTED = "Your device is supported!";
    this.PHONE_SUPPORTED_OS_UPDATE_REQUIRED = "Your device is supported, however an operating system (OS) update is needed.";
    this.PHONE_SUPPORTED_STREAMING_NOT_AVAILABLE = "Your device is supported, however streaming is not available.";
    this.PHONE_SUPPORTED_TWO_WAY_AUDIO_CAPABLE = "Your device is supported and capable of hands-free conversations with 2-Way Audio!";
    this.PHONE_NOT_FULLY_TESTED = "Your device is not fully tested. In the interim, you can download the app, as many features work without needing to stream.";

    /* Download Statements - Starkey */
    this.GENESIS_DOWNLOAD_STATEMENT = "Download the My Starkey app"
    this.EVOLV_DOWNLOAD_STATEMENT = "Download the Thrive app"

    /* Download Statements - Audibel */
    this.INTRIGUE_DOWNLOAD_STATEMENT = "Download the My Audibel app"
    this.ARC_DOWNLOAD_STATEMENT = "Download the Thrive app"

    /* Download Statement - Caregiver (third option) */
    this.CAREGIVER_DOWNLOAD_STATEMENT = "Download the Hear Share app"

    /* Icon File Names (located in /assets/icons) */
    this.SUPPORTED_ICON_NAME = "Supported"
    this.UNRECOGNIZED_DEVICE_ICON_NAME = "UnrecognizedDevice"
    this.UNSUPPORTED_ICON_NAME = "Unsupported"
    this.UNSUPPORTED_PC_ICON_NAME = "UnsupportedPC"
    this.OS_UPDATE_OR_NO_STREAMING_ICON_NAME = "OSUpgradeOrNoStreaming"

    /* App Links */
    this.MYSTARKEY_LINK = "MYSTARKEY_LINK"
    this.MYAUDIBEL_LINK = "MYAUDIBEL_LINK"
    this.THRIVE_LINK = "THRIVE_LINK"
  }

  public createNewCompatabilityResultDataObject(): CompatabilityResultData {
    return {
      compatabilityStatement: "",
      iconFileName: ""
    }
  }

  public deviceNotSupported(): CompatabilityResultData {
    return {
      compatabilityStatement: this.PHONE_NOT_SUPPORTED,
      iconFileName: this.UNSUPPORTED_ICON_NAME
    }
  }

  public deviceSupported(): CompatabilityResultData {
    return {
      compatabilityStatement: this.PHONE_SUPPORTED,
      iconFileName: this.SUPPORTED_ICON_NAME
    }
  }

  public deviceOSUpdateRequired(): CompatabilityResultData {
    return {
      compatabilityStatement: this.PHONE_SUPPORTED_OS_UPDATE_REQUIRED,
      iconFileName: this.OS_UPDATE_OR_NO_STREAMING_ICON_NAME
    }
  }

  public deviceSupportedStreamingNotAvailable(): CompatabilityResultData {
    return {
      compatabilityStatement: this.PHONE_SUPPORTED_STREAMING_NOT_AVAILABLE,
      iconFileName: this.OS_UPDATE_OR_NO_STREAMING_ICON_NAME
    }
  }

  public deviceNotFullyTested(): CompatabilityResultData {
    return {
      compatabilityStatement: this.PHONE_NOT_FULLY_TESTED,
      iconFileName: this.UNRECOGNIZED_DEVICE_ICON_NAME
    }
  }

  public deviceSupportedTwoWayAudioCapable(): CompatabilityResultData {
    return {
      compatabilityStatement: this.PHONE_SUPPORTED_TWO_WAY_AUDIO_CAPABLE,
      iconFileName: this.SUPPORTED_ICON_NAME
    }
  }
}
