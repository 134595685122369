import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Params, Router } from '@angular/router';
import { ConstantsService } from 'src/app/services/constants.service';
import { DeviceCompatabilityService } from 'src/app/services/device-compatability.service';

@Component({
  selector: 'app-aid-type-form',
  templateUrl: './aid-type-form.component.html',
  styleUrls: ['./aid-type-form.component.less']
})
export class AidTypeFormComponent implements OnInit {
  private compatabilityStatement: string;
  private icon: string;
  private downloadStatement: string = "";
  private appLink: string = "";
  public isAudibel: boolean = false;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private constantsService: ConstantsService,
              private deviceCompatabilityService: DeviceCompatabilityService) {
                const compatabilityData = this.deviceCompatabilityService.getCompatability();
                this.compatabilityStatement = compatabilityData.compatabilityStatement;
                this.icon = compatabilityData.iconFileName;
              }

  public onUserTypeClick(userType: string) {
    console.log(userType);
    let isCareGiver = false;
    if(userType == 'Caregiver') {
      isCareGiver = true;
      this.compatabilityStatement = this.constantsService.PHONE_SUPPORTED;
      this.icon = this.constantsService.SUPPORTED_ICON_NAME;
    }
    this.setDownloadStatement(userType);
    const navigationExtras: NavigationExtras = {
      state: {
        isCareGiver: isCareGiver,
        compatabilityStatement: this.compatabilityStatement,
        downloadStatement: this.downloadStatement,
        appLink: this.appLink,
        icon: this.icon
      },
      queryParamsHandling: "merge"
    };
    this.router.navigate(['compatability'], navigationExtras)
  }

  private setDownloadStatement(userType: string)  {
    switch(userType) {
      case 'Genesis': {
        this.downloadStatement = this.constantsService.GENESIS_DOWNLOAD_STATEMENT;
        this.appLink = "GenesisAppLink" //my starkey
        break;
      }
      case 'Evolv': {
        this.downloadStatement = this.constantsService.EVOLV_DOWNLOAD_STATEMENT;
        this.appLink = "EvolvAppLink" // thrive
        break;
      }
      case 'Caregiver': {
        this.downloadStatement = this.constantsService.CAREGIVER_DOWNLOAD_STATEMENT;
        this.appLink = "CaregiverAppLink" // hearshare
        break;
      }
      case 'Intrigue': {
        this.downloadStatement = this.constantsService.INTRIGUE_DOWNLOAD_STATEMENT;
        this.appLink = "IntrigueAppLink" // hearshare
        break;
      }
      case 'Arc': {
        this.downloadStatement = this.constantsService.ARC_DOWNLOAD_STATEMENT;
        this.appLink = "ArcAppLink" // hearshare
        break;
      }
    }
  }

  private loadAidBrand() {

    this.route.queryParams.subscribe(params => {
      let aidBrand = params['brand'];
      if(aidBrand === "audibel") {
        this.isAudibel = true;
        console.log(this.isAudibel)
        //this.router.navigate(['aidTypeForm'], {queryParams: {'brand': 'audibel'}, queryParamsHandling: "merge"})
      }
    });
  }

  ngOnInit(): void {
    this.loadAidBrand();
  }

}
