import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConstantsService } from 'src/app/services/constants.service';

@Component({
  selector: 'app-compatability-result',
  templateUrl: './compatability-result.component.html',
  styleUrls: ['./compatability-result.component.less']
})

export class CompatabilityResultComponent implements OnInit {
  isCareGiver: boolean = false;
  compatabilityStatement: string = "";
  downloadStatement: string = "";
  appLink: string = "";
  icon: string = "";
  isAudibel: boolean = false;
  isIncompatible: boolean = false;

  constructor(private router: Router, private route: ActivatedRoute, private constantsService: ConstantsService) {
    const navigation = this.router.getCurrentNavigation();
    if(navigation && navigation.extras.state) {
      const state = navigation.extras.state as {
        isCareGiver: boolean,
        compatabilityStatement: string,
        downloadStatement: string,
        appLink: string,
        icon: string
      };

      console.log("HI THERE");
      console.log(state);

      this.isCareGiver = state.isCareGiver;
      this.compatabilityStatement = state.compatabilityStatement;
      if(this.compatabilityStatement == this.constantsService.PHONE_NOT_SUPPORTED) {
        this.isIncompatible = true;
      }
      this.downloadStatement = state.downloadStatement;
      this.appLink = state.appLink;
      this.icon = state.icon;
    }
    else {
     this.router.navigate(['aidTypeForm', {queryParamsHandling: "merge"}])
    }
   }

   private loadAidBrand() {

    this.route.queryParams.subscribe(params => {
      let aidBrand = params['brand'];
      console.log(aidBrand)
      if(aidBrand === "audibel") {
        this.isAudibel = true;
        console.log(this.isAudibel)
        //this.router.navigate([''], {queryParams: {'brand': 'audibel'}, queryParamsHandling: "merge"})
      }
    });
  }

  ngOnInit(): void {
    this.loadAidBrand();
  }

}
