import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Params, Router } from '@angular/router';
import { UserLocationService } from './services/user-location.service';
import { LookupResponse } from 'ipdata';
import { mergeMap, Observable } from 'rxjs';
import { DeviceCompatabilityService } from './services/device-compatability.service';
import { ConstantsService } from './services/constants.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit {
  title: string = "phone-support-website";
  aidBrand: string = "";
  isAudibel: boolean = false;
  private userIpData$: Observable<Observable<LookupResponse>>;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private userLocationService: UserLocationService,
              private deviceCompatabilityService: DeviceCompatabilityService,
              private constantsService: ConstantsService,) {
                  this.loadAidBrand();
                  this.userIpData$ = this.userLocationService.getUserCountry();
                  this.userIpData$.pipe(
                    mergeMap(innerObservable => innerObservable)
                  ).subscribe(locationData => {
                    if(!deviceCompatabilityService.checkIfMobileDevice()) {
                      this.router.navigate(['pcnotsupported'], { relativeTo: this.route, queryParamsHandling: "merge"})
                    }
                    else if(locationData.country_name != "United States") {
                      this.routeStraightToCompatability();
                    }
                    else if(this.router.url == "/"){
                      this.router.navigate(['aidTypeForm'])
                    }

                  })


              }

  private getUserLanguage() {

  }

  private routeStraightToCompatability() {
    const compatabilityData = this.deviceCompatabilityService.getCompatability();
    const compatabilityStatement = compatabilityData.compatabilityStatement;
    const icon = compatabilityData.iconFileName;
    const downloadStatement = this.constantsService.EVOLV_DOWNLOAD_STATEMENT;
    const appLink = this.constantsService.THRIVE_LINK;
    const navigationExtras: NavigationExtras = {
      state: {
        isCareGiver: false,
        compatabilityStatement: compatabilityStatement,
        downloadStatement: downloadStatement,
        appLink: appLink,
        icon: icon
      },
      queryParamsHandling: "merge"
    };
    this.router.navigate(['compatability'], navigationExtras)

  }

  private loadAidBrand() {
    this.route.queryParams.subscribe(params => {
      this.aidBrand = params['brand'];
      if(this.aidBrand === "audibel") {
        this.isAudibel = true;
      }
    });
  }

  ngOnInit() {
    this.getUserLanguage();
  }

}


