<div class="appContainer">
  <!-- LOGO -->
  <app-logo [isAudibel]="isAudibel"></app-logo>

  <!-- BANNER -->
  <app-banner [isAudibel]="isAudibel"></app-banner>

  <router-outlet></router-outlet>

</div>




