import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom, map, Observable } from 'rxjs';
import { LookupResponse } from 'ipdata';
import { environment } from 'src/environments/environment';
import { EnvironmentConfig } from '../model/environment-config';


@Injectable({
  providedIn: 'root'
})
export class UserLocationService {
  constructor(private http: HttpClient) {}

  public getUserCountry() {
    return this.getConfiguration().pipe(
      map(result => {
        return this.http.get<LookupResponse>(result.ipDataUrl);
      })
    )
  }


  private getConfiguration(): Observable<any> {
   return this.http.post(`${environment.authorizationServiceUrl}/getFirebaseConfig`, null);
  }


}
