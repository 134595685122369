<div>
  <div class="icon">
    <img src="assets/icons/{{icon}}.svg">
  </div>
  <div class="content">
    {{compatabilityStatement}}
  </div>
  <ng-container *ngIf="!isIncompatible; else incompatible">
    <div [ngClass]="{'downloadButton': !isAudibel, 'downloadButtonAudibel': isAudibel}">
      <a href="{{appLink}}" title="Download now">{{downloadStatement}}</a>
    </div>
  </ng-container>
  <ng-template #incompatible>
    <div [ngClass]="{'content': !isAudibel, 'contentAudibel': isAudibel}">
      <ng-container *ngIf="!isAudibel; else audibel">
        Please <a href="https://www.starkey.com/hearing-aids/apps">tap here</a> for a compatability list.
      </ng-container>
      <ng-template #audibel>
        Please <a href="https://www.audibel.com">tap here</a> for a compatability list.
      </ng-template>
    </div>
  </ng-template>
</div>

