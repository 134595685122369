import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AidTypeFormComponent } from './components/aid-type-form/aid-type-form.component';
import { CompatabilityResultComponent } from './components/compatability-result/compatability-result.component';
import { PcNotSupportedComponent } from './components/pc-not-supported/pc-not-supported.component';
import { MyAudibelRerouteComponent } from './my-audibel-reroute/my-audibel-reroute.component';
import { MyStarkeyRerouteComponent } from './my-starkey-reroute/my-starkey-reroute.component';

const routes: Routes = [
  { path: 'aidTypeForm', component: AidTypeFormComponent},
  { path: 'pcnotsupported', component: PcNotSupportedComponent},
  { path: 'compatability', component: CompatabilityResultComponent},
  { path: 'MyStarkey', component: MyStarkeyRerouteComponent },
  { path: 'MyAudibel', component: MyAudibelRerouteComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
