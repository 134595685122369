import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { ConstantsService } from '../services/constants.service';
import { DeviceCompatabilityService } from '../services/device-compatability.service';

@Component({
  selector: 'app-my-audibel-reroute',
  templateUrl: './my-audibel-reroute.component.html',
  styleUrls: ['./my-audibel-reroute.component.less']
})
export class MyAudibelRerouteComponent implements OnInit {

  constructor(private router: Router,
              private deviceCompatabilityService: DeviceCompatabilityService,
              private constantsService: ConstantsService) {
    this.routeStraightToCompatability()
  }

  private routeStraightToCompatability() {
    const compatabilityData = this.deviceCompatabilityService.getCompatability();
    const compatabilityStatement = compatabilityData.compatabilityStatement;
    const icon = compatabilityData.iconFileName;
    const downloadStatement = this.constantsService.INTRIGUE_DOWNLOAD_STATEMENT;
    const appLink = this.constantsService.MYSTARKEY_LINK;
    const navigationExtras: NavigationExtras = {
      state: {
        isCareGiver: false,
        compatabilityStatement: compatabilityStatement,
        downloadStatement: downloadStatement,
        appLink: appLink,
        icon: icon
      },
      queryParamsHandling: "merge"
    };
    this.router.navigate(['compatability'], navigationExtras)

  }

  ngOnInit(): void {
  }

}
