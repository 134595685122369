import { Component, Input, OnInit } from '@angular/core';
import { ConstantsService } from 'src/app/services/constants.service';

@Component({
  selector: 'app-pc-not-supported',
  templateUrl: './pc-not-supported.component.html',
  styleUrls: ['./pc-not-supported.component.less']
})
export class PcNotSupportedComponent implements OnInit {
  compatabilityStatement : string;
  constructor(private constantsService: ConstantsService) {
    this.compatabilityStatement = this.constantsService.PC_NOT_SUPPORTED;
   }

  ngOnInit(): void {
  }

}
