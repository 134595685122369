import { Injectable } from '@angular/core';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';
import { CompatabilityResultComponent } from '../components/compatability-result/compatability-result.component';
import { CompatabilityResultData } from '../model/compatability-result-data';
import { ConstantsService } from './constants.service';
import ashaPhones from '../../assets/android-phones-data/asha_phones.json'
import supportedPhones from '../../assets/android-phones-data/supported_phones.json'

declare function getRenderer(complete: any): any


@Injectable({
  providedIn: 'root'
})
export class DeviceCompatabilityService {
  deviceInfo: DeviceInfo;

  constructor(private deviceService: DeviceDetectorService,
              private constantsService: ConstantsService) {
    this.deviceInfo = deviceService.getDeviceInfo();
  }

  // Returns false if device is not mobile
  public checkIfMobileDevice(): boolean {
    if(this.deviceInfo.deviceType != 'mobile' && this.deviceInfo.deviceType != 'tablet') {
      return false;
    }
    else {
      return true;
    }
  }

  public getMobileDeviceBrand() {
    switch(this.deviceInfo.device) {
      case 'iPhone': {
        return 'iPhone';
      }
      case 'iPad': {
        return 'iPad'
      }
      default: {
        return 'Android'
      }
    }
  }

  public getCompatability(): CompatabilityResultData {

    switch(this.deviceInfo.device) {
      case 'iPhone': {
        return this.getiPhoneCompatability();
      }
      case 'iPad': {
        return this.getiPadCompatability();
      }
      default: {
        return this.getAndroidCompatability();
      }
    }
  }

  public getiPhoneCompatability(): CompatabilityResultData {
    let result = this.constantsService.createNewCompatabilityResultDataObject();

    const height = window.screen.height;
    const width = window.screen.width;
    const heightWidthRatio = height/width;
    if(heightWidthRatio <= 1.5) { // 1, 3G, 3GS, 4, 4S
      result = this.constantsService.deviceNotSupported();
    }
    else if(!this.checkIfIOSVersionIsThriveCompatible()) {
      result = this.constantsService.deviceOSUpdateRequired();
    }
    else if(heightWidthRatio == 1.775) { // 5, 5S, 5C, SE 1st Gen
      getRenderer((value: any) => {
        const possibleCPUs:[string] = value.split('|');
        if(possibleCPUs.includes("Apple A6 GPU")) { // if it is 5/5C
          result = this.constantsService.deviceNotSupported();
        }
        else {
          result = this.constantsService.deviceSupported();
        }
      })
    }
    else if(heightWidthRatio == 1.7777777777777777) {
      result = this.constantsService.deviceSupported();
    }     // X  , XS, 11 Pro, 12 mini, 13 mini    ||      XS Max, XR, 11, 11 Pro Max              6, 6S, 7, 8, SE 2nd Gen, SE 3rd Gen
    else if(heightWidthRatio == 2.1653333333333333 || heightWidthRatio == 2.1642512077294684 || heightWidthRatio == 1.7786666666666666) {
      result = this.constantsService.deviceSupported();
    }
    else { // 14_Pro, 12, 12 Pro, 13, 13 Pro, 14, 12 Pro Max, 13 Pro Max,
      if(this.checkIfIOSVersionIsTwoWayCompatible()) {
        result = this.constantsService.deviceSupportedTwoWayAudioCapable();
      }
      else {
        result = this.constantsService.deviceSupported();
      }
    }

    return result;
  }

  private checkIfIOSVersionIsTwoWayCompatible(): boolean {
    const iosVersionArray = this.getIOSVersionArray();
    if(iosVersionArray && iosVersionArray[0] >= 15) {
      return true;
    }
    else {
      return false;
    }
  }

  private checkIfIOSVersionIsThriveCompatible() {
    const iosVersionArray = this.getIOSVersionArray();
    if(iosVersionArray && iosVersionArray[0] >= 12) {
      return true;
    }
    else {
      return false;
    }
  }

  private getIOSVersionArray(): number[] | null {
    const iosVersion: RegExpMatchArray | null = (window.navigator.userAgent).match(/OS (\d+)_(\d+)_?(\d+)?/);
    if(iosVersion) {
      const iosVersionArray: number[] = [parseInt(iosVersion[1]), parseInt(iosVersion[2] || '0'), parseInt(iosVersion[3] || '0')];
      console.log(iosVersionArray);
      return iosVersionArray;
    }
    return null;
  }

  public getiPadCompatability(): CompatabilityResultData {
    const height = window.screen.height;
    const width = window.screen.width;
    const pixelRatio = window.devicePixelRatio;
    console.log(height + " " + width + " " + pixelRatio)
    if(height/width == 1024 / 768) {
      // iPad, iPad 2, iPad Mini
      if(pixelRatio == 1) {
        return this.constantsService.deviceNotSupported();
      }
      else { // iPad 3, 4, 5, Mini 2, Mini 3, Mini 4, Air, Air 2, Pro 9.7
        return this.constantsService.deviceSupported();
      }
    } // iPad Pro 10.5
    else if (height / width == 1112 / 834) {
      return this.constantsService.deviceSupported();    }
    else if ( height/ width == 1366 / 1024) {
      return this.constantsService.deviceSupported();
    }
    else {
      return this.constantsService.deviceSupported();
    }
  }

  public getAndroidCompatability(): CompatabilityResultData {
    const userAgent = window.navigator.userAgent;
    const androidVersion = this.getAndroidVersion(userAgent);
    if(androidVersion == undefined) {
      return this.constantsService.deviceNotSupported();
    }
    const isStreamingSupportedOSVersion = this.checkIfAndroidVersionIsStreamingCompatible(androidVersion);
    const isSupportedOSVersion = this.checkIfAndroidVersionIsCompatible(androidVersion)
    console.log(userAgent)
    console.log("Android Version: " + androidVersion)
    console.log("Streaming Supported? = " + isStreamingSupportedOSVersion)
    console.log("OS Supported? = " + isSupportedOSVersion)

    if(this.checkIfAndroidModelIsInAshaPhones(userAgent)) {
      if(isStreamingSupportedOSVersion) {
        return this.constantsService.deviceSupported();
      }
      else if(isSupportedOSVersion) {
        return this.constantsService.deviceSupportedStreamingNotAvailable();
      }
      else {
        return this.constantsService.deviceOSUpdateRequired();
      }
    }
    else if(this.checkIfAndroidModelIsInSupportedPhones(userAgent)) {
      if(isSupportedOSVersion) {
        return this.constantsService.deviceSupportedStreamingNotAvailable();
      }
      else {
        return this.constantsService.deviceOSUpdateRequired();
      }
    }
    else if(isSupportedOSVersion){
      return this.constantsService.deviceNotFullyTested();
    }
    else {
      return this.constantsService.deviceNotSupported();
    }
  }

  private getAndroidVersion(userAgent: string) {
    const androidVersionMatchArray: RegExpMatchArray | null = userAgent.match(/Android\s([0-9\.]*)/i);
    let androidVersion = undefined;
    if(androidVersionMatchArray) {
      return androidVersionMatchArray[1];
    }
    return androidVersion;
  }

  private checkIfAndroidVersionIsStreamingCompatible(androidVersion: string) {
    return parseInt(androidVersion) >= 10 ? true : false
  }

  private checkIfAndroidVersionIsCompatible(androidVersion: string) {
    return parseInt(androidVersion) >= 7 ? true : false
  }

  private checkIfAndroidModelIsInAshaPhones(userAgent: string): boolean {
    const ashaPhonesMap = new Map(Object.entries(ashaPhones));
    for (const [key, value] of ashaPhonesMap) {
      let ashaPhonesInnerMap = new Map(Object.entries(value));
      for(const [key, value] of ashaPhonesInnerMap) {
        let regStr = RegExp('(' + value[0] + ')');
        if(userAgent.match(regStr) != null) {
          console.log(value)
          console.log("IN ASHA PHONES")
          return true;
        }
      }
    }
    return false;
  }

  private checkIfAndroidModelIsInSupportedPhones(userAgent: string): boolean {
    const supportedPhonesMap = new Map(Object.entries(supportedPhones));
    for (const [key, value] of supportedPhonesMap) {
      let supportedPhonesInnerMap = new Map(Object.entries(value));
      for(const [key, value] of supportedPhonesInnerMap) {
        let regStr = RegExp('(' + value[0] + ')');
        if(userAgent.match(regStr) != null) {
          console.log(value)
          console.log("IN SUPPORTED PHONES")
          return true;
        }
      }
    }
    return false;
  }


}
